import { useStaticQuery, graphql } from "gatsby";

export default function useSearchData() {
  const data = useStaticQuery(graphql`
  {
    allSitePage: allSitePage {
      edges {
        node {
          context {
            metatag {
              tag
              attributes {
                content
                href
                name
                rel
              }
            }
            heroImage {
              alt
              publicUrl
              url
              gatsbyImage {
                height
                layout
                width
                placeholder {
                  fallback
                }
                images {
                  fallback {
                    sizes
                    src
                    srcSet
                  }
                  sources {
                    sizes
                    srcSet
                    type
                  }
                }
              }
            }
            fieldHeroHeading
            fieldHeroBody {
              value
            }
            fieldHeroTitle
            body{
              value
            }
            contentType: content_type
            fieldResourceDescription
             entityUrl {
                path
              }
              title
              entityLabel
              fieldPageTitle
              industryId
  
              industryIds {
                id
                label
                targetSideId
              }
              solutionIds {
                id
                label
                targetSideId
              }
              contentTypeId {
                id
                label
              }
  
              entityCreated
              details {
                title
                location {
                  name
                }
                job_id
              }
              locale
            }
            path
        }
      }
    }
  }`)

  let result = data?.allSitePage?.edges;

  return result;
}
