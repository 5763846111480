import React, { useState, useEffect } from "react";
import * as JsSearch from "js-search";
import { get, isEmpty } from "src/utils";
import { useQueryParam, StringParam, ArrayParam } from "use-query-params";
import Helmet from "react-helmet";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import useSearchData from "src/hooks/useSearchData";
import loadable from '@loadable/component'
import "src/scss/search/_searchPage.scss";
import CirclUp from "../../static/circle-up-solid.svg";
import {setTabsData, searchRanking} from "src/components/helpers/setSearchRanking";
import { LocalizedLink,SEO } from "src/components/commonComponents";
import { getPageDataJsonPath } from "src/utils";
import TopResults from "src/components/Search/TopResults";

const SearchField = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.SearchField
});
const SearchFeed = loadable(() => import('src/components/Search/SearchFeed'))
const SearchTabData = loadable(() => import('src/components/Search/SearchTabData'))
const FilterSideMenu = loadable(() => import('src/components/Search/FilterSideMenu'))

/**
 * Compare function to be used in sort() to sort the filter name by alphabetical order
 */
const compare = (a, b) => {
  const filterB = b.name.toUpperCase();
  const filterA = a.name.toUpperCase();

  let comparison = 0;
  if (filterA > filterB) {
    comparison = 1;
  } else if (filterA < filterB) {
    comparison = -1;
  }
  return comparison;
};
/**
 * @param {Array of Objects} list Result of the search, in other words, a Page Object, with pageContext from Gatsby
 * @param {String} checkAgainst Name of the key inside list that should be extracted into unique values. This key should be
 * an array for now and not a CSV.
 */
const extractUniqueSearchFilterOptions = (list, checkAgainst, targetSideId) => {
  const uniqueValues = [];
  list.forEach((value) => {
    const valueFromCheckAgainst = value.node.context[checkAgainst]; // can be `industryNames`, `contentTypeId`...

    if (valueFromCheckAgainst) {
      if (checkAgainst === "contentTypeId") {
        valueFromCheckAgainst.forEach((result) => {
          const isInside = uniqueValues?.some((type) => type.id === result.id);
          if (!isInside && result && result.id.length !== 0) {
            uniqueValues.push(result);
          }
        });
      } else if (Array.isArray(valueFromCheckAgainst)) {
        valueFromCheckAgainst.forEach((result) => {
          const isInside = uniqueValues?.some((type) => type.id === result.id);
          if (!isInside && result && result.id && result?.targetSideId === targetSideId) {
            uniqueValues.push(result);
          }
        });
      }
    }
  });
  const uniqueObject = uniqueValues.map((value) => {
    return { name: value.label, id: value.id, isActive: false, targetSideId: value.targetSideId };
  });
  return uniqueObject.sort(compare);
};

/** ------------------------------------------
 * @Page
 */
const Search = ({ pageContext }) => {
  const { locale } = pageContext;
  /**
   * @Searching
   *    @searchInput - what the user types in the form
   *    @searchEngine - the engine of the search created by `search-js`
   *    @searchResult - the result of the search made by `search-js` in the website pages.
   *    The search result is constant until the point the user click the search button again.
   */
  const [searchInput, setSearchInput] = useState(undefined);
  const [searchEngine, setSearchEngine] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const [groupByResult, setGroupByResult] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  let data = useSearchData();

  // Function to check if any object in the array has attributes with name "noindex"
  const hasNoIndexAttribute = (metatags) => {
    if (!metatags) return true
    for (const obj of metatags) {
        if (obj.attributes && obj.attributes.content && obj.attributes.content.includes('noindex') ) {
            return true;
        }
    }
    return false;
  }

  data = data?.filter(dt=>(dt?.node?.context?.locale === locale && !hasNoIndexAttribute(dt?.node?.context?.metatag)))

  let checkForAllTab = activeTab === "All" ? true : false
  /**
   * @SearchEngineSetup
   */
  useEffect(() => {
    const searchLibrary = new JsSearch.Search(["node", "path"]);
    // Strategy
    searchLibrary.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();

    //Sanitizer
    searchLibrary.sanitizer = new JsSearch.LowerCaseSanitizer();

    // INDEXES
    searchLibrary.addIndex(["node", "context", "title"]);
    searchLibrary.addIndex(["node", "context", "industryNames"]);
    searchLibrary.addIndex(["node", "context", "entityLabel"]);
    searchLibrary.addIndex(["node", "context", "fieldPageTitle"]);
    searchLibrary.addIndex(["node", "context", "fieldHeroHeading"]);
    searchLibrary.addIndex(["node", "context", "fieldHeroPreHeading"]);
    searchLibrary.addIndex(["node", "context", "fieldHeroSubHeading"]);
    searchLibrary.addIndex(["node", "context", "details", "title"]);
    searchLibrary.addIndex(["node", "path"]);

    //Documents
    const documentsArray = [];

    // Filter by locale. Depending on the language, it can be set here
    data?.forEach((page) => {
      if (!page.node?.context || page.node?.context?.locale === locale) {
        /**
         * Documents in the search index must meet one of the following requirements to be searchable.
         * Note: `page.node.context.details` is for greenhouse job postings.
         */
        if (page.node?.context?.entityLabel || page.node?.context?.details || page.node?.context?.title) {
          documentsArray.push(page);
        }
      }
    });
    searchLibrary.addDocuments(documentsArray);

    setSearchEngine(searchLibrary);
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  /** ------------------------------------------
   * @Filtering
   *    @filters Here is both the possible filters and the filters that are active. Inside each filter key inside this State, should
   *    be made ouf of objects with Name and isActive as keys. When isActive = true, then the search result is filtered by this
   *    option.
   *    state = {
   *      industry: [{ name: "Corporate", isActive: false }, { ... }],
   *      otherFilter: [ ... ]
   *    }
   *    @filteredSearchResult evertime there is a active filter, the search result will be filtered and set here
   *    if there is no active filters, this will be set to null.
   */
  const [filters, setFilters] = useState(undefined);
  const [filteredSearchResult, setFilteredSearchResult] = useState(null);

  /**
   * @FilterClick
   * To be passed to FilterSideMenu as a function to set the filter based on which one it is clicked
   */
  const handleFilterClick = (filterOptionObject, lead) => {
    // if lead = industry, filters[lead] = filters.industry
    const clickedFilter = filters[lead].filter(
      (filter) => filter.name === filterOptionObject.name
    )[0];

    // Take the clicked filter OUT of the current state (industry for example),
    // so we can set the new object with the new isActive value
    const filterWithoutClickedFilter = filters[lead].filter(
      (filter) => filter.id !== filterOptionObject.id
    );

    // Change the object of clickedFilter to set isActive to the opposite of what it was
    clickedFilter.isActive = !clickedFilter.isActive;
    const newSpecificFilterArray = [
      ...filterWithoutClickedFilter,
      clickedFilter,
    ].sort(compare);

    setFilters({ ...filters, [lead]: newSpecificFilterArray });
  };

  /** ------------------------------------------
   * @Watcher for uploading search result to match the active filters
   * this will have to be done by hand, because the structure of the filtering might change
   */
  useEffect(() => {
    if (searchResult && filters) {
      const activeIndustryFilters = filters.industry
        .filter((obj) => obj.isActive)
        .map((obj) => obj.id);

      const activeSolutionFilters = filters.solution
        .filter((obj) => obj.isActive)
        .map((obj) => obj.id);

      const activeResourceFilters = filters.resource
        .filter((obj) => obj.isActive)
        .map((obj) => obj.id);

      let filteredSearch = Array.from(searchResult);

      if (activeIndustryFilters.length !== 0) {
        activeIndustryFilters.forEach((industryFilter) => {
          filteredSearch = filteredSearch.filter((searchObj) => {
            let shouldBeInside = false;
            // if there is industryNames in the said search object
            if (searchObj.node.context.industryIds) {
              shouldBeInside = searchObj.node.context.industryIds?.some(
                (industry) => industry.id === industryFilter
              );
            }
            return shouldBeInside;
          });
        });
      }
      if (activeSolutionFilters.length !== 0) {
        activeSolutionFilters.forEach((solutionFilter) => {
          filteredSearch = filteredSearch.filter((searchObj) => {
            let shouldBeInside = false;
            // if there is industryNames in the said search object
            if (searchObj.node.context.solutionIds) {
              shouldBeInside = searchObj.node.context.solutionIds?.some(
                (solution) => solution.id === solutionFilter
              );
            }
            return shouldBeInside;
          });
        });
      }
      if (activeResourceFilters.length !== 0) {
        activeResourceFilters.forEach((resourceFilter) => {
          filteredSearch = filteredSearch.filter((searchObj) => {
            let shouldBeInside = false;
            // if there is industryNames in the said search object
            if (searchObj.node.context.contentTypeId) {
              shouldBeInside = searchObj.node.context.contentTypeId?.some(
                (type) => type.id === resourceFilter
              );
            }
            return shouldBeInside;
          });
        });
      }
      if (
        activeIndustryFilters.length === 0 &&
        activeSolutionFilters.length === 0 &&
        activeResourceFilters.length === 0
      ) {
        filteredSearch = null;
      }
      setFilteredSearchResult(filteredSearch);
    }
    // eslint-disable-next-line
  }, [filters]);

  /** ------------------------------------------
   * @onSubmit from form
   * Here the Industry and Solution filters are set to the sidebar filter menu
   */
  const handleSearch = (e, searchInputFromParam, activeFiltersFromParam) => {
    e.preventDefault();
    // If the searchInput comes from URL param, it takes searchInputFromParam instead of searchInput (which is set in the input)
    const correctSearchInput = searchInputFromParam
      ? searchInputFromParam
      : searchInput;
    if (correctSearchInput && correctSearchInput.length >= 3) {
      let resultOfSearch = searchEngine.search(correctSearchInput);
      var groupResults = resultOfSearch?.length>0 ? resultOfSearch.reduce(function(r, a) {
        r[a?.node?.context?.contentType] = r[a?.node?.context?.contentType] || [];
        r[a?.node?.context?.contentType].push(a);
        return r;
      }, Object.create(null)): null;
      if(resultOfSearch?.length>0){
        groupResults["All"] = resultOfSearch;
        groupResults = setTabsData(groupResults);
      }
      // Returns object as such [ { name: Corporate, isActive: false }, { ... } ]
      const uniqueIndustryValues = extractUniqueSearchFilterOptions(
        resultOfSearch,
        "industryIds",
        pageContext?.target_site_tid
      );
      const uniqueSolutionValues = extractUniqueSearchFilterOptions(
        resultOfSearch,
        "solutionIds",
        pageContext?.target_site_tid
      );
      const uniqueResourceValues = extractUniqueSearchFilterOptions(
        resultOfSearch,
        "contentTypeId",
        pageContext?.target_site_tid
      );

      // If there is active filters coming from URL Param set the filter as already active
      // It will probably need to be changed later
      if (activeFiltersFromParam && activeFiltersFromParam.industryParam) {
        uniqueIndustryValues.forEach((valueObj) => {
          const isInURL = activeFiltersFromParam.industryParam.includes(
            valueObj.id
          );
          if (isInURL) {
            valueObj.isActive = true;
          }
        });
      }
      if (activeFiltersFromParam && activeFiltersFromParam.solutionParam) {
        uniqueSolutionValues.forEach((valueObj) => {
          const isInURL = activeFiltersFromParam.solutionParam.includes(
            valueObj.id
          );
          if (isInURL) {
            valueObj.isActive = true;
          }
        });
      }
      if (activeFiltersFromParam && activeFiltersFromParam.resourceParam) {
        uniqueResourceValues.forEach((valueObj) => {
          const isInURL = activeFiltersFromParam.resourceParam.includes(
            valueObj.id
          );
          if (isInURL) {
            valueObj.isActive = true;
          }
        });
      }
      setFilters({
        ...filters,
        industry: uniqueIndustryValues,
        solution: uniqueSolutionValues,
        resource: uniqueResourceValues,
      });
      
      resultOfSearch = searchRanking(resultOfSearch)
      setSearchResult(resultOfSearch);
      setGroupByResult(groupResults);
      setError(null);
      setLoading(false);
    } else {
      setError("Search term must contain at least three characters");
      setSearchResult(null);
      setFilters(undefined);
      setLoading(false);
    }
  };
  // Every change when user types
  const onChange = (e) => {
    setSearchInput(e.target.value);
  };

  /** ------------------------------------------
   * @QueryParamURL
   *  - Queries: search(String); industry(Array); solution(Array)
   *  - Everytime a parameter in the URL changes, it needs to reflect in the state in the component
   *  - Everytime filter or search changes the URL must also change
   *  - When loading the screen with query params on the URL, the search and filtering must be done
   */
  const [industryParam, setIndustryParam] = useQueryParam(
    "industry",
    ArrayParam
  );
  const [solutionParam, setSolutionParam] = useQueryParam(
    "solution",
    ArrayParam
  );
  const [resourceParam, setResourceParam] = useQueryParam(
    "resource",
    ArrayParam
  );
  const [searchParam, setSearchParam] = useQueryParam("query", StringParam);

  // Set industry array of filters everytime filters change, if there is any active Filters
  useEffect(() => {
    //--- INDUSTRY ---
    let activeIndustryFilters;
    let activeSolutionFilters;
    let activeResourceFilters;
    if (filters) {
      activeIndustryFilters = filters.industry
        .filter((obj) => obj.isActive)
        .map((obj) => obj.id);
      activeSolutionFilters = filters.solution
        .filter((obj) => obj.isActive)
        .map((obj) => obj.id);
      activeResourceFilters = filters.resource
        .filter((obj) => obj.isActive)
        .map((obj) => obj.id);
    }

    if (
      industryParam !== activeIndustryFilters &&
      (!isEmpty(activeIndustryFilters) || !isEmpty(industryParam))
    ) {
      setIndustryParam(
        isEmpty(activeIndustryFilters) ? undefined : activeIndustryFilters,
        "replaceIn"
      );
    }

    if (
      solutionParam !== activeSolutionFilters &&
      (!isEmpty(activeSolutionFilters) || !isEmpty(solutionParam))
    ) {
      setSolutionParam(
        isEmpty(activeSolutionFilters) ? undefined : activeSolutionFilters,
        "replaceIn"
      );
    }

    if (
      resourceParam !== activeResourceFilters &&
      (!isEmpty(activeResourceFilters) || !isEmpty(resourceParam))
    ) {
      setResourceParam(
        isEmpty(activeResourceFilters) ? undefined : activeResourceFilters,
        "replaceIn"
      );
    }
    // eslint-disable-next-line
  }, [filters]);

  // Set search query evertime the search result changes
  useEffect(() => {
    if (searchResult && searchParam !== searchInput) {
      setSearchParam(searchInput);
    }
    // eslint-disable-next-line
  }, [searchResult]);

  // If there is a ?query="" in the URL, this is called and the search is set
  useEffect(() => {
    // set search input like searchParam
    if (searchParam && searchEngine) {
      setSearchInput(searchParam);
      handleSearch({ preventDefault: () => { } }, searchParam, {
        industryParam,
        solutionParam,
        resourceParam,
      });
    }
    // eslint-disable-next-line
  }, [searchParam, searchEngine]);

  // ------------------------------------------
  const OGMarkup = getOGMarkup("Search", 'search');
  const newResults = filteredSearchResult ? checkForAllTab ? filteredSearchResult?.length : groupByResult[activeTab]?.length : checkForAllTab ? searchResult?.length : groupByResult[activeTab]?.length;
  const pageDataPath = getPageDataJsonPath(`/${locale == 'en-gb' ? "uk/search" : "search"}`)
  return (
    <div className="search-wrapper tw-min-h-[50vh]">
      <Helmet
        bodyAttributes={{
          class: "searchpage",
        }}
      />
      <SEO
        title={`Search | ${locale == 'en-gb' ? "UK | Accruent" : "Accruent"}`}
        description = {`Accruent's software helps workplace & asset management organizations unify their built environments for better management of people, places, and resources ${locale == 'en-gb' ? "| Search | UK" : "| Search"}`}
        pageUrl={`/${locale == 'en-gb' ? "uk/search" : "search"}`}
        OGMarkup={OGMarkup}
        preloads= {[pageDataPath]}
      />
      {/* ---- FORM ---- */}
      <div className="search-gradient-bg tw-bg-link-color tw-bg-no-repeat tw-bg-0% tw-bg-origin-padding">
        <div className="container">
          <div className="section !tw-px-4 !tw-py-8 md:!tw-px-[60px]">
            <form role="search" onSubmit={handleSearch}>
              <SearchField searchBarClass="tw-rounded-l-2xl tw-text-2xl tw-tracking-[0.22px] tw-leading-[27px] tw-font-Poppins" onChange={onChange} value={searchInput || ""} />
              {error && <small className="has-text-danger">{error}</small>}
            </form>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="section">
          {/* --- SEARCH RESULTS ---- */}
          {loading ? (
            <div className="has-text-center search-spinner border-l-accruent_sapphire border-b-accruent_sapphire tw-m-auto tw-h-12 tw-w-12"></div>
          ) : Array.isArray(searchResult) && isEmpty(searchResult) ? (
            <div className="no-search-result tw-flex tw-flex-col md:tw-flex-row">
              <div className="tw-w-full md:tw-w-1/2">
                <p className=" tw-text-[20px] tw-font-Poppins">0 results found for <span className=" tw-text-accruent_sapphire tw-font-bold tw-font-Poppins">"{searchParam}"</span></p>
                <p className="tw-mb-0">Your search did not return any results. </p>
                <p>Try again using fewer criteria or a different search term.</p>
              </div>
              <div className="tw-w-full md:tw-w-1/2">
                <TopResults classProp = {'!tw-mt-0 !tw-block'} locale={locale}/>
              </div>
            </div>
          ) : !get(searchResult, "0") ? (
            <div className="no-search-result tw-min-h-[80vh]">
              <p>Waiting for search input.</p>
            </div>
          ) : (
            <div>
            {searchParam && <p className="results-count tw-pt-4 tw-text-xl tw-leading-[23.2px] tw-tracking-[.18px] tw-mb-[38px] tw-font-Poppins">{newResults} total results found for <span className="tw-text-accruent_sapphire tw-text-xl tw-leading-[23.2px] tw-tracking-[.18px] tw-font-bold">{`"${searchParam}"`}</span></p>}
         
            {groupByResult && <SearchTabData groupByResult={groupByResult} activeTab={activeTab} setActiveTab={setActiveTab} setFilters={setFilters} searchParam={searchParam}/>}
              <div className="columns filter-box">
                {searchResult && searchResult.length !== 0 && checkForAllTab &&(
                  <div className="column is-3">
                    <FilterSideMenu
                      filterOptions={filters}
                      onFilterClick={handleFilterClick}
                      locale={locale}
                    />
                  </div>
                )}
                <div className="column is-9">
                  <div className="search-results">
                    <SearchFeed
                      searchResult={
                        filteredSearchResult ? checkForAllTab ? filteredSearchResult : groupByResult[activeTab] : checkForAllTab ? searchResult: groupByResult[activeTab]
                      }
                      searchInput={searchInput}
                    />
                  </div>
                {newResults > 3 && <div className="search-footer tw-pt-[37px] tw-flex tw-gap-4 tw-items-center tw-justify-center">
                  <div>
                    <LocalizedLink to={"#search"}>
                      <img src={CirclUp} decoding = "async" className="tw-w-10"/>
                    </LocalizedLink>
                  </div>
                  <div>
                    <LocalizedLink to={"#search"} className="back-btn tw-text-accruent_sapphire tw-text-base tw-leading-4 tw-font-Poppins tw-font-bold">
                      Go back up
                    </LocalizedLink>
                  </div>
                  {/* <div>
                    <LocalizedLink to={"#search"} className = "send-fdb">
                      Send feedback
                    </LocalizedLink>
                  </div> */}
                </div>}
                </div>
                {!checkForAllTab && <div className="tw-w-1/3">
                  <TopResults locale={locale}/>
                </div>}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
