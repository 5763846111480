export const setTabsData = (groupResults) => {
  let finalTabObj = {};
  Object.keys(groupResults).forEach((group, index) => {
    switch (group) {
      case "All":
        finalTabObj["All"] = groupResults["All"]
        break;
      case "accruent_product":
        finalTabObj["Products"] = groupResults["accruent_product"]
        break;
      case "null":
        finalTabObj["Others"] = groupResults["null"]
        break;
      default:
        finalTabObj[group?.replaceAll("_", " ")] = groupResults[group]
    }
  })
  return (finalTabObj);
}

export const searchRanking = (data) => {
  let productsData = []
  productsData = data.filter(x => (x?.node?.context?.contentType === "accruent_product" || x?.node?.context?.contentType === "solutions"))
  data = data.filter(x => (x?.node?.context?.contentType !== "accruent_product" && x?.node?.context?.contentType !== "solutions"))
  data = [...productsData, ...data]
  return data;
}
// export default {setTabsData, searchRanking};