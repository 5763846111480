import React from 'react'
import { LocalizedLink } from '../commonComponents/localizedLink'
import findSiteType from '../helpers/findSiteType'
const TopResults = ({classProp="", locale}) => {
  const isMc = findSiteType("mc");
  const isEms = findSiteType("ems");
  const checkForEmsMc = isMc || isEms
  console.log("locale",locale)
  const topProducts = [{
    name: "EMS",
    link: checkForEmsMc ? "https://www.accruent.com/products/ems" :`${locale === "en-gb" ? '/uk':''}/products/ems`
  },
  {
    name: "Maintenance Connection",
    link: checkForEmsMc ? "https://www.accruent.com/products/maintenance-connection" : `${locale === "en-gb" ? '/uk':''}/products/maintenance-connection`
  },
  {
    name: "Meridian",
    link: checkForEmsMc ? "https://www.accruent.com/products/meridian" :`${locale === "en-gb" ? '/uk':''}/products/meridian`
  },
  {
    name: "Lucernex",
    link: checkForEmsMc ? "https://www.accruent.com/products/lucernex" :`${locale === "en-gb" ? '/uk':''}/products/lucernex`
  }
  ]
  return (
    <div className={`tw-mt-4 tw-bg-accruent_lightgray tw-rounded-2xl tw-hidden md:tw-block ${classProp}`}>
      <div className='tw-p-[20px_16px_16px_16px]'>
        <div className='tw-flex tw-flex-row tw-gap-2'>
          <i className="fa-sharp fa-regular fa-trophy-star tw-text-accruent_sapphire tw-text-[24px]"></i>
          <p className=' tw-text-mobileLgFont md:tw-text-lgFont tw-text-accruent_sapphire tw-font-Poppins tw-mb-0'>Top Products</p>
        </div>
        <div className="tw-border-[1px] tw-border-solid tw-border-accruent_gray tw-opacity-[1] tw-mt-[17px] tw-mr-[15px] tw-mb-[25px]"></div>
        <div>
          {topProducts?.map((product, index) => {
            return (
              <LocalizedLink className='tw-flex tw-flex-row tw-justify-between tw-mb-[19px]' key={index} to={product?.link}>
                <p className='tw-text-blue_tag tw-mb-0 tw-font-Poppins tw-text-[16px] tw-leading-[23.2px] tw-tracking-[0.14px]'>{product?.name}</p>
                <i class="fa-solid fa-arrow-right tw-text-[16px] tw-text-blue_tag"></i>
              </LocalizedLink>
            )
          })}

        </div>
      </div>
    </div>
  )
}
export default TopResults;